import { LANGUAGES, NAMESPACES } from 'constants/locales'
import { SIGNUP_CAPTCHA } from 'constants/experiments'

import React, { useCallback, useState, useMemo, useEffect } from 'react'
import dynamic from 'next/dynamic'
import LanguageSwitcher from 'components/atoms/language-switcher'
import { oneOf, shape, string } from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { getEmbedAdminAppName } from 'lib/embed'
import TypeformLogo from 'components/atoms/common/logo'
import { useOptimizelyContext } from 'components/optimizely/optimizely-provider'
import { useEnvContext } from 'components/env-context'
import { isExperimentVariationActive } from 'utils/optimizely'
import { SelectPositions } from 'components/atoms/language-switcher/constants'

import Spinner from '../modified-kitt/spinner'
import {
  ReCAPTCHADisclaimerStyles,
  SpinnerContainer,
} from '../okta-widget/okta-widget.styles'
import {
  trackSignupLanguageSwitcherOptionClick,
  trackSignupFormSubmit,
  trackSignupPageLogoClick,
  trackSignupIntentWithEmail,
} from '../tracking'

import { OKTA_SIGNUP_STATUSES, PAGE_LOCALES } from './constants'
import {
  PageContainer,
  SignupPanel,
  AuthSection,
  LanguageSwitcherWrapper,
  Container,
  LogoWrapper,
  SocialButtonsWrapper,
  FormHeadline,
} from './okta-signup-page.styles'
import LoginLink from './login-link'
import {
  generateState,
  getSignupWidgetConfig,
  onError,
  trackSignupSuccessOnGTMDataLayer,
  getLoginHintFromSearchParams,
} from './helpers'
import ConsentsPortal from './consents/consents-portal'
import SignupSuccess from './signup-success'
import SocialButtons from './social-buttons'
import AuthDivider from './auth-divider'
import SidePanel from './side-panel'
import {
  getSubmitEle,
  overrideErrorMessages,
  toggleErrorBox,
} from './dom-utils'
import OktaWidgetSignupCustomValidation from './okta-widget-custom-signup-validation'
import { EmailSignupButton } from './email-signup-button'

const ReCAPTCHADisclaimer = () => {
  const { t } = useTranslation(NAMESPACES.AUTH)

  return (
    <ReCAPTCHADisclaimerStyles
      dangerouslySetInnerHTML={{ __html: t('auth.recaptcha.disclaimer') }}
    />
  )
}

const OktaWidget = dynamic(() => import('../okta-widget'), {
  ssr: false,
  loading: () => (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ),
})

const OktaSignupPage = ({
  locale,
  oktaConfig,
  device,
  justWidget = false,
  onRegistrationComplete = () => {},
}: {
  locale: string
  oktaConfig: {
    oktaClientId: string
    oktaDomain: string
    socialIdpIds: { google: string; microsoft: string; linkedin: string }
  }
  device: string
  justWidget: boolean
  onRegistrationComplete: () => void
}) => {
  const { asPath } = useRouter()
  const { t } = useTranslation(NAMESPACES.AUTH)
  const embedAdminAppName = getEmbedAdminAppName(asPath)
  const openSocialAuthInNewTab = embedAdminAppName !== null
  const oktaState = useMemo(
    () => generateState(openSocialAuthInNewTab),
    [openSocialAuthInNewTab]
  )
  const { oktaClientId, oktaDomain, socialIdpIds } = oktaConfig
  const shouldShowForm = asPath.includes('#email')
  const [isSignupSuccess, setIsSignupSuccess] = useState(false)
  const [isShowingForm, setShowingForm] = useState(shouldShowForm)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [isWidgetRendered, setIsWidgetRendered] = useState(false)
  const { experiments } = useOptimizelyContext()

  useEffect(() => {
    // We are checking if the login_hint is present in the URL in the client side
    // to prevent caching of the login_hint value in the server side
    const loginHint = getLoginHintFromSearchParams()

    if (loginHint) {
      setShowingForm(true)
    }
  }, [])

  const onSuccess = (response: { status: string; username: string }) => {
    if (response.status === OKTA_SIGNUP_STATUSES.ACTIVATION_EMAIL_SENT) {
      const userEmailFromResponse = response.username
      setIsSignupSuccess(true)
      setUserEmail(userEmailFromResponse)
      trackSignupSuccessOnGTMDataLayer(device)
      onRegistrationComplete()
    }
  }

  const { RECAPTCHA_KEY } = useEnvContext()
  const CAPTCHA_ENABLED = isExperimentVariationActive({
    experiments,
    experimentId: SIGNUP_CAPTCHA.ID,
    variationId: SIGNUP_CAPTCHA.VARIATIONS.ON,
  })

  const signupWidgetConfig = getSignupWidgetConfig({
    locale,
    device,
    t,
    RECAPTCHA_KEY,
    CAPTCHA_ENABLED,
  })

  const getFormTranslations = useCallback(() => {
    return {
      'registration.form.title': t('auth.signup.form.title'),
      'registration.form.submit': t('auth.signup.form.button-submit'),
      'auth.form.consents-error': t('auth.form.consents-error'),
      'model.validation.field.invalid.format.email': t(
        'auth.signup.form.email.invalid-format'
      ),
    }
  }, [t])

  const handleLanguageSwitcherOptionClick = useCallback(
    () => trackSignupLanguageSwitcherOptionClick({ locale }),
    [locale]
  )

  const handleSubmit = useCallback(() => {
    toggleErrorBox(false)
  }, [])

  const handleSubmitListeners = useCallback(
    (action = 'add') => {
      const submitEle = getSubmitEle()

      if (submitEle) {
        // @ts-expect-error
        const method = submitEle[`${action}EventListener`]

        method('submit', handleSubmit)
      }
    },
    [handleSubmit]
  )

  const onAfterRender = useCallback(() => {
    handleSubmitListeners()
    setIsWidgetRendered(true)
  }, [handleSubmitListeners])

  const onAfterError = useCallback(() => {
    toggleErrorBox(true)
    overrideErrorMessages(t)
  }, [t])

  useEffect(() => {
    return () => {
      handleSubmitListeners('remove')
    }
  }, [handleSubmitListeners])

  const handleSubmitTrack = () => {
    trackSignupFormSubmit()
    // Associate signup with affiliate
    fetch(`/api/v1/referral/cj/signup/`, { method: 'POST' })
  }

  const handleSignupEmail = () => {
    trackSignupIntentWithEmail()
    setShowingForm(true)
  }

  const oktaWidgetProps = {
    pageName: 'signup',
    locale: locale,
    oktaConfig: oktaConfig,
    flowSpecificConfig: signupWidgetConfig,
    onSuccess: onSuccess,
    onError: onError,
    onSubmitTrack: handleSubmitTrack,
    onAfterRender: onAfterRender,
    onAfterError: onAfterError,
    formTranslations: getFormTranslations(),
    PortalComponent: ConsentsPortal,
  }

  const oktaWidget =
    isSignupSuccess && userEmail ? (
      <SignupSuccess email={userEmail} />
    ) : (
      <>
        {CAPTCHA_ENABLED && (
          <Script
            src={`https://www.recaptcha.net/recaptcha/enterprise.js?render=${RECAPTCHA_KEY}`}
          ></Script>
        )}
        <OktaWidget
          ExtraComponents={CAPTCHA_ENABLED && <ReCAPTCHADisclaimer />}
          {...oktaWidgetProps}
        />
        <OktaWidgetSignupCustomValidation isWidgetRendered={isWidgetRendered} />
      </>
    )

  if (justWidget) return oktaWidget

  return (
    <PageContainer>
      <SidePanel />
      <SignupPanel>
        <AuthSection>
          <LanguageSwitcherWrapper>
            <LanguageSwitcher
              locale={locale}
              pageLocales={PAGE_LOCALES}
              onLanguageSwitcherOptionClick={handleLanguageSwitcherOptionClick}
              position={SelectPositions.Bottom}
            />
          </LanguageSwitcherWrapper>
          <LoginLink embedAdminAppName={embedAdminAppName} />
          <Container>
            <LogoWrapper>
              <TypeformLogo
                title='Typeform'
                href={t('auth.logo.url')}
                target='_top'
                onClick={trackSignupPageLogoClick}
              />
            </LogoWrapper>
            {(isSignupSuccess && userEmail && (
              <SignupSuccess email={userEmail} />
            )) || (
              <>
                <FormHeadline>{t('auth.signup.form.title')}</FormHeadline>
                {isShowingForm ? (
                  oktaWidget
                ) : (
                  <>
                    <SocialButtons
                      socialIdpIds={socialIdpIds}
                      clientId={oktaClientId}
                      oktaDomain={oktaDomain}
                      state={oktaState}
                      key='social-buttons'
                      embedAdminAppName={embedAdminAppName}
                      device={device}
                    />
                    <AuthDivider />
                    <SocialButtonsWrapper>
                      <EmailSignupButton
                        handleClick={handleSignupEmail}
                        openInNewTab={openSocialAuthInNewTab}
                      >
                        {t('auth.form.switch-to-email')}
                      </EmailSignupButton>
                    </SocialButtonsWrapper>
                  </>
                )}
              </>
            )}
          </Container>
        </AuthSection>
      </SignupPanel>
    </PageContainer>
  )
}

OktaSignupPage.displayName = 'OktaSignupPage'

OktaSignupPage.propTypes = {
  locale: oneOf(LANGUAGES).isRequired,
  oktaConfig: shape({
    oktaDomain: string,
    oktaClientId: string,
    oktaGoogleSignupId: string,
    oktaMicrosoftSignupIdpId: string,
  }).isRequired,
  device: string,
}

OktaSignupPage.defaultProps = {
  device: null,
}

export default OktaSignupPage
